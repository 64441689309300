'use client' // Error components must be Client Components

import {useEffect} from 'react'

export default function Error({
    error,
    reset,
}: {
    error: Error & {digest?: string}
    reset: () => void
}) {
    useEffect(() => {
        // Log the error to an error reporting service
        console.error(error)
    }, [error])

    return (
        <div className="mt-12">
            <h2 className="text-secondary dark:text-dark-secondary">Something went wrong!</h2>
            <button type="button" onClick={reset}>
                Try again
            </button>
        </div>
    )
}
